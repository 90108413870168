export const uppercasePattern = /[A-Z]/;
export const lowercasePattern = /[a-z]/;
export const digitPattern = /[\d]/;
export const specialCharactersPattern = /[!@#$%^&*(),\.?":{}|<>;'+_\-\]\[\]\\/=]/;

export default (password = '') =>
  password.length > 7 &&
  digitPattern.test(password) &&
  uppercasePattern.test(password) &&
  lowercasePattern.test(password) &&
  specialCharactersPattern.test(password);
