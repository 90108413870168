import {
  compose,
  hoistStatics,
  withProps,
  InferableComponentEnhancer
} from 'recompose';

import withAppMeta, { WithAppMetaProps } from './withAppMeta';

export type WithPathProps = {
  path: string;
  params: { [key: string]: any };
};

const withPath = compose(
  withAppMeta,
  withProps(
    ({ appMeta: { path, routerParams }, ...ownerProps }: WithAppMetaProps) => ({
      path,
      params: routerParams,
      ...ownerProps
    })
  )
) as InferableComponentEnhancer<{}>;

export default hoistStatics(withPath);
