import { RouterProvider } from '@anm/contexts/RouterContext';
import { FC } from 'react';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';

import withPath, { WithPathProps } from './withPath';
import withSetupApi from './withSetupApi';
import withWrapper from './withWrapper';

export type BootstrapPageProps = WithPathProps;

const RouterProviderWrapper: FC<BootstrapPageProps> = ({
  path,
  params,
  children
}) => <RouterProvider value={{ path, params }}>{children}</RouterProvider>;

const bootstrapPage = compose(
  connect(),
  withPath,
  withSetupApi,
  hoistStatics(withWrapper(RouterProviderWrapper) as any)
);

export default bootstrapPage;
