import { ComponentType } from 'react';

const withWrapper = (Wrapper: ComponentType) => (Component: ComponentType) => (
  props: any
) => (
  <Wrapper {...props}>
    <Component {...props} />
  </Wrapper>
);

export default withWrapper;
