import { SubscriptionPeriod } from '@anm/api/modules/subscription';
import BillingPeriod from '@anm/components/subscription/BillingPeriod';
import { ANNUAL, MONTHLY } from '@anm/constants/subscription';
import roundPrice from '@anm/helpers/pricing/roundPrice';
import noop from 'lodash/fp/noop';
import { useCallback, FC } from 'react';

import { BillingPeriodBlockWrapper } from './Wrapper';

export type BillingPeriodBlockProps = {
  annualPrice: number;
  monthlyPrice: number;
  selectedPeriod: SubscriptionPeriod;
  disable?: SubscriptionPeriod;
  onChange?: (period: SubscriptionPeriod) => void;
};

const BillingPeriodBlock: FC<BillingPeriodBlockProps> = ({
  disable,
  annualPrice,
  monthlyPrice,
  selectedPeriod,
  onChange = noop as (period: SubscriptionPeriod) => void
}) => {
  const handleChangeAnnual = useCallback(() => onChange(ANNUAL), []);
  const handleChangeMonthly = useCallback(() => onChange(MONTHLY), []);

  return (
    <BillingPeriodBlockWrapper>
      <BillingPeriod
        name="annualPeriod"
        value={ANNUAL}
        label="Annual"
        price={roundPrice(annualPrice)}
        disabled={disable === ANNUAL}
        isChecked={selectedPeriod === ANNUAL}
        onChange={handleChangeAnnual}
      />
      <BillingPeriod
        name="monthlyPeriod"
        value={MONTHLY}
        label="Monthly"
        price={roundPrice(monthlyPrice)}
        disabled={disable === MONTHLY}
        isChecked={selectedPeriod === MONTHLY}
        onChange={handleChangeMonthly}
      />
    </BillingPeriodBlockWrapper>
  );
};

export default BillingPeriodBlock;
