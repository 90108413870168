import { AnimatronProduct } from '@anm/api/modules/auth';
import isServer from '@anm/helpers/is/isServer';
import { toObject } from '@anm/helpers/parseParams';
import { NextInitialProps } from 'anm-next';
import { FC } from 'react';
import { compose, hoistStatics, InferableComponentEnhancer } from 'recompose';

import vtConfig, { EnvType } from '../config';
import setupApi from '../helpers/setupApi';
import { selectAppMetaProduct } from '../store/modules/appMeta/selectors';

import withAppMeta, { WithAppMetaProps } from './withAppMeta';
import withInitialProps from './withInitialProps';
import withWrapper from './withWrapper';

type SetupApiWrapperProps = {
  product: AnimatronProduct;
  envName: EnvType;
  clientIp?: string;
};
const setupApiWrapper = ({
  product,
  envName,
  clientIp
}: SetupApiWrapperProps) => {
  const { urls } = vtConfig(envName);
  const { api, apiWave } = urls;
  const productApiUrlMap: { [key in AnimatronProduct]: string } = {
    WAVE: apiWave,
    STUDIO: api
  };

  setupApi({
    clientIp,
    baseURL: productApiUrlMap[product] || api
  });
};

let lastEnvName: EnvType;
const initialApi = ({
  req,
  store,
  query
}: NextInitialProps<{ env: EnvType }>) => {
  const { env: envName } = query;
  const product = selectAppMetaProduct(store.getState());

  setupApiWrapper({
    product,
    envName: lastEnvName || envName,
    clientIp: req?.connection.remoteAddress
  });
};

const SetupApiWrapper: FC<WithAppMetaProps> = ({
  appMeta: { product },
  children
}) => {
  if (!isServer()) {
    const { env } = toObject(location.search);
    lastEnvName = lastEnvName || env;
    setupApiWrapper({ product, envName: lastEnvName });
  }

  return <>{children}</>;
};

export default compose(
  withInitialProps(initialApi, true),
  hoistStatics(
    compose(
      withAppMeta,
      withWrapper(SetupApiWrapper as any)
    ) as InferableComponentEnhancer<{}>
  )
);
