export const validateCardNumber = (cardNumber: string) =>
  window?.Stripe?.card?.validateCardNumber(cardNumber)
    ? null
    : 'Incorrect card number';

export const validateExpiry = (date: string) => {
  const [month, year] = date.split('/');
  window?.Stripe?.card?.validateExpiry(month, year)
    ? null
    : 'Invalid expiry date';
};

export const validateCVC = (cvc: string) =>
  window?.Stripe?.card?.validateCVC(cvc) ? null : 'Invalid CVC number';
