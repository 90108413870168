import { SubscriptionPeriod } from '@anm/api/modules/subscription';
import BillingPeriodBlock from '@anm/components/subscription/BillingPeriodBlock';
import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

export type BillingPeriodsInputProps = FieldRenderProps<
  SubscriptionPeriod,
  HTMLDivElement
> & {
  annualPrice: number;
  monthlyPrice: number;
  disablePeriod?: SubscriptionPeriod;
};

const BillingPeriodsInput: FC<BillingPeriodsInputProps> = ({
  annualPrice,
  monthlyPrice,
  disablePeriod,
  input: { value, onChange }
}) => (
  <BillingPeriodBlock
    {...{ annualPrice, monthlyPrice, onChange }}
    disable={disablePeriod}
    selectedPeriod={value}
  />
);

export default BillingPeriodsInput;
