import { SubscriptionPeriod } from '@anm/api/modules/subscription';
import RadioButton, {
  RadioButtonProps
} from '@anm/components/form/RadioButton';
import { FC } from 'react';

import RadioLabel from './RadioLabel';
import { BillingPeriodButton } from './Wrapper';

type BillingPeriodName = 'monthlyPeriod' | 'annualPeriod';

export type BillingPeriodProps = RadioButtonProps<
  SubscriptionPeriod,
  BillingPeriodName
> & {
  price: number;
};

const BillingPeriod: FC<BillingPeriodProps> = props => {
  const { name, price, disabled, isChecked } = props;

  return (
    <BillingPeriodButton {...{ price, disabled, isChecked }}>
      <RadioButton<SubscriptionPeriod>
        {...props}
        label={
          <RadioLabel price={price} isAnnual={name === 'annualPeriod'}>
            {props.label}
          </RadioLabel>
        }
      />
    </BillingPeriodButton>
  );
};

export default BillingPeriod;
